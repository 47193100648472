<template>
    <div>
        <div>
            <el-table
                    ref="multipleTable"
                    :data="orderlist"
                    tooltip-effect="dark"
                    style="width: 100%"
            >
                <el-table-column show-overflow-tooltip label="名称" sortable>
                    <template slot-scope="scope">
                        <div class="ordertable">
                            <div class="ygdleft">
                                <p class="name">{{ scope.row.name }}</p>

                            </div>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column show-overflow-tooltip label="预计出刊日期" sortable>
                    <template slot-scope="scope">
                        <div class="ordertable">
                            <div class="ygdleft">
                                <p class="publishtime">{{ scope.row.publishtime }}</p>
                            </div>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column show-overflow-tooltip label="出刊日期(加急)" sortable>
                    <template slot-scope="scope">
                        <div class="ordertable">
                            <div class="ygdleft">
                                <p class="urgentDate">{{ scope.row.urgentDate }}</p>
                            </div>
                        </div>
                    </template>
                </el-table-column>

                <el-table-column label="审核状态" prop="Approval_time" sortable>
                    <template slot-scope="scope">
                        <div class="ordertable">
                            <div class="ygdright">
                                <div style="height: 20px;"></div>
                                <span v-if="scope.row.isUrgent == 0">未开通</span>
                                <span v-if="scope.row.isUrgent == 1">待审核</span>
                                <span v-if="scope.row.isUrgent == 2">审核通过</span>
                            </div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column
                        show-overflow-tooltip
                        label="操作"
                        sortable

                >
                    <template slot-scope="scope">
                        <el-button @click="receive(scope.row.isUrgent,scope.row.publishtime)" type="text" size="small"
                        >日期设置(加急)
                        </el-button
                        >
                    </template>
                    <!--                    <template slot-scope="scope">-->
                    <!--                        <div class="ordertable">-->
                    <!--                            <div class="ygdright">-->
                    <!--                                <div style="height: 20px"></div>-->
                    <!--                                <span @click="receive(scope.row.isUrgent)" style="cursor: pointer"-->
                    <!--                                >日期设置(加急)</span-->
                    <!--                                >-->
                    <!--                            </div>-->
                    <!--                        </div>-->
                    <!--                    </template>-->
                </el-table-column>
            </el-table>
        </div>

        <el-dialog
                title="加急日期设置"
                :visible.sync="dialog"
                width="503px"
                :close-on-click-modal="false"
                center
        >
            <el-date-picker v-model="form.urgentDate" type="date" placeholder="选择日" @change="dataSearch"
                            value-format="yyyy-MM-dd"></el-date-picker>

            <el-button type="danger" @click="confirm1()" :loading="loading" style="float: right">确 定</el-button>
        </el-dialog>
    </div>

</template>

<script>

    import {applyUrgent, isUrgent, updateUrgentDate} from "../../../API/QKD/setting";

    export default {
        data() {
            return {
                src: "",
                dialog: false, //默认不显示弹窗
                form: {
                    urgentDate: ""
                },
                publishtime: ""
            }
        },
        methods: {
            //弹窗
            receive: function (state, publishtime) {
                // alert(1);
                let id = localStorage.getItem('QKId')
                if (state == 0) {
                    this.$message.error("请先进行加急申请，等待审核通过！")
                } else if (state == 1) {
                    this.$message.error("审核未通过，请等待审核通过后进行维护！")
                } else if (state == 2) {
                    //显示弹框
                    this.dialog = true;
                }

                this.publishtime = publishtime;

            },
            confirm1() {

                let id = localStorage.getItem('QKId')
               // alert(this.publishtime);
                if(this.form.urgentDate>this.publishtime && this.publishtime!=''){
                    return this.$message.success("加急日期不允许晚于预计出刊日期！")
                }
                updateUrgentDate(id, this.form.urgentDate).then(res => {
                    console.log(res)
                    if (res.data.code == 0) {
                        this.$message.success(res.data.msg)
                        this.dialog = false;
                        this.orderdata();
                    } else {
                        this.$message.error(res.data.msg)
                        this.dialog = false;
                    }
                })
            },
            orderdata() {
                let id = localStorage.getItem('QKId')
                isUrgent(id, this.currentPage, this.pagesize).then(res => {
                    console.log(res)
                    this.orderlist = res.data.data;
                    this.orderlistlength = this.orderlist.length
                    this.total = res.data.total
                })
            },

        },

        props: ["orderlist"],
        mounted() {

        }
    }


</script>

<style>
    .orderlist {
        position: relative;
        display: flex;
        justify-content: space-between;
        background: #f7f7f7;
        border-radius: 4px;
        padding: 10px;
        margin-top: 10px;
        cursor: pointer;
    }

    .name {
        font-size: 14px;
    }

    .orderlist span {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        margin: 0 3px;
    }

    .urgent {
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        height: 24px;
        background: #ff1918;
        border-radius: 0px 4px 0px 4px;
        color: #fff;
        text-align: center;
        font-size: 12px;
        line-height: 24px;
    }

    .nourgent {
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        height: 24px;
        background: lightseagreen;
        border-radius: 0px 4px 0px 4px;
        color: #fff;
        text-align: center;
        font-size: 12px;
        line-height: 24px;
    }

    .ygdPage {
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>