<template>
    <div>
        <div class="qkxx_title">加急开通申请</div>
        <div class="searchContainerBtn">
            <el-button type="danger" @click="ordersCount()">申请开通</el-button>
        </div>
        <div v-if="orderlistlength == 0" class="nodata">暂无数据</div>
        <div v-else>
            <div class="qkcont">
                <order :orderlist="orderlist"></order>
            </div>
            <div class="ygdPage">
                <el-pagination
                        class="fy"
                        small
                        @size-change="handleSizeChange"
                        :page-size="pagesize"
                        :page-sizes="[10, 20, 30]"
                        layout="total, sizes, prev, pager, next, jumper"
                        @current-change="current_change"
                        :total="total"
                        background
                >
                </el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import order from '../Setting/urgentList.vue'
    import {applyUrgent, isUrgent} from "../../../API/QKD/setting.js"

    export default {
        components: {
            order
        },
        data() {
            return {
                son: 1,
                path: '/Periodical/isUrgent',
                now: 8,
                childpath: '/Periodical/balanced/detail',
                total: 0, //默认数据总数
                pagesize: 10, //每页的数据条数
                currentPage: 1, //默认开始页面
                orderlist: [ //订单列表数据

                ],
                orderlistlength: 2, //订单列表长度
                trace: [ //跟踪数据

                ],
                article: {}
            }
        },
        methods: {
            // 分页
            current_change: function (currentPage) {
                this.currentPage = currentPage;
                this.orderdata()
            },
            handleSizeChange(val) {
                console.log(val);
                this.pagesize = val;
                this.orderdata()
            },
            orderdata() {
                let id = localStorage.getItem('QKId')
                isUrgent(id, this.currentPage, this.pagesize).then(res => {
                        console.log(res)
                        this.orderlist = res.data.data;
                        this.orderlistlength = this.orderlist.length
                        this.total = res.data.total
                })
            },
            ordersCount() {

                let id = localStorage.getItem('QKId')
                isUrgent(id, this.currentPage, this.pagesize).then(res => {
                    if(res.data.code == 3){
                       return  this.$message.success(res.data.msg);
                    }else {
                        applyUrgent(id).then(res => {
                            console.log(res)
                            if (res.data.code == 0) {
                                this.$message.success("申请成功,平台将在24小时内进行审核!")
                                this.orderdata();
                            } else {
                                this.$message.error(res.data.msg)
                            }
                        })
                    }

                });


            }
        }, mounted() {
            this.orderdata()
        }

    }
</script>

<style>
    .qkcont {
        padding: 10px;
    }

    .nodata {
        text-align: center;
        line-height: 80px;
        color: #e6e6e6;
    }

    .btn {
        display: flex;
        justify-content: flex-end;
        padding: 20px;
    }
</style>